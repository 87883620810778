import {
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Spinning } from "./loading";
import { URL } from "../common/constant";
import { GoBackButton } from "../common/goBackButtonComponent";
import { ErrorModal } from "./successModal";
import { CategorySelection } from "./categoryComponent";
import axios from 'axios';
// const axios = require("axios").default;

export const AdditionalEnergyMeterInstallation = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const history = useHistory();
  const [is_loading, setLoading] = useState(false);
  const [is_submitted, setSubmitted] = useState(false);
  const schema = yup.object().shape({
    division: yup.number().min(1, "This field is required").required(),
    additional_meters: yup
      .number()
      .required("This field is required [Please enter only number(s)]"),
    location: yup.string().required("This field is required"),
    contract_account: yup
      .number()
      .min(10000000, "Contract account number should be greater than 8 digits")
      .max(
        999999999,
        "Contract account number should not be greater than 9 digits"
      )
      .required("This field is required(Please enter only digits)"),
    reason: yup.string().required("This field is required"),
    category: yup.string().required("This field is required"),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
    site_ready_checbox: yup
      .bool()
      .required()
      .oneOf([true], "This checkbox must be ticked"),
    energy_meter_capacity: yup.number(),
  });

  const handleFormSubmit = (values) => {
    setLoading(true);
    axios
      .post(
        `${URL}/services/energy-meters/`,
        { ...values, request_type: "Addition" },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(function (response) {
        setLoading(false);
        setSubmitted(true);
        history.push(`/ticket/${response.data.id}?success`);
      })
      .catch(function (error) {
        setLoading(false);
        setShowModal(true);
        if (error.response.status === 400) {
          if (error.response.data["energy_meter_capacity"]) {
            setModalContent(
              `${error.response.data["energy_meter_capacity"]} for 'Power Required' Field`
            );
          } else {
            setModalContent(JSON.stringify(error.response.data));
          }
        } else setModalContent("Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later");
      });
  };

  return (
    <Container>
      <GoBackButton />
      <h3 className="text-center mt-2">Additional energy meters</h3>
      <hr></hr>

      <Formik
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values) => handleFormSubmit(values)}
        initialValues={{
          division: "",
          additional_meters: "",
          contract_account: "",
          location: "",
          reason: "",
          terms: false,
          site_ready_checbox: false,
          energy_meter_capacity: "",
          category: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isInvalid,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-2">
                <FloatingLabel label="Division">
                  <Form.Select
                    placeholder="Division"
                    name="division"
                    value={values.division}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.division && !!errors.division}
                    isValid={touched.division && !errors.division}
                    disabled={is_submitted}
                  >
                    <option value="0">Choose one / Reset</option>
                    {props.divisions.map((division) => (
                      <option key={division.id} value={division.id}>
                        {division.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.division}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Name of location ">
                  <Form.Control
                    type="text"
                    placeholder="Name of location "
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.location && !!errors.location}
                    isValid={touched.location && !errors.location}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.location}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="No. of energy meters required">
                  <Form.Control
                    type="number"
                    placeholder="Number of additional energy meter required"
                    name="additional_meters"
                    value={values.additional_meters}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.additional_meters && !!errors.additional_meters
                    }
                    isValid={
                      touched.additional_meters && !errors.additional_meters
                    }
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.additional_meters}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Consumer No.">
                  <Form.Control
                    type="number"
                    placeholder="Consumer No."
                    name="contract_account"
                    value={values.contract_account}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.contract_account && !!errors.contract_account
                    }
                    isValid={
                      touched.contract_account && !errors.contract_account
                    }
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contract_account}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-3 mb-md-0">
                <InputGroup>
                  <FloatingLabel label="Power Required">
                    <Form.Control
                      type="number"
                      placeholder="Power Required"
                      name="energy_meter_capacity"
                      value={values.energy_meter_capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.energy_meter_capacity &&
                        !!errors.energy_meter_capacity
                      }
                      isValid={
                        touched.energy_meter_capacity &&
                        !errors.energy_meter_capacity
                      }
                      disabled={is_submitted}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.energy_meter_capacity}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <InputGroup.Text>kW</InputGroup.Text>
                </InputGroup>
                <Form.Text className="text-muted">
                  {" "}
                  Load for the new energy meter
                </Form.Text>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2">
                <FloatingLabel label="Reason">
                  <Form.Control
                    type="text"
                    placeholder="Reason"
                    name="reason"
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.reason && !!errors.reason}
                    isValid={touched.reason && !errors.reason}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.reason}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <CategorySelection
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                is_submitted={is_submitted}
                label={"Area"}
              />
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Check
                  required
                  name="site_ready_checbox"
                  label="Site ready for installation of energy meter(s)"
                  onChange={handleChange}
                  isInvalid={!!errors.site_ready_checbox}
                  feedback={errors.site_ready_checbox}
                  feedbackType="invalid"
                  id="site_ready_checbox"
                  disabled={is_submitted}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.site_ready_checbox}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} className="mb-2 ">
                <Form.Check
                  required
                  name="terms"
                  label="I assure that all the information filled in above are true and that I am liable for any
                            action by the company if any of the information provided are incorrect."
                  onChange={handleChange}
                  isInvalid={!!errors.terms}
                  feedback={errors.terms}
                  feedbackType="invalid"
                  id="terms Feedback"
                  disabled={is_submitted}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.terms}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group as={Col} className="mt-2">
              <Button type="submit" disabled={is_loading || is_submitted}>
                {is_loading ? <Spinning /> : null}
                Submit
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <ErrorModal
        showModal={showModal}
        setShowModal={() => setShowModal(!showModal)}
        modalContent={modalContent}
      />
    </Container>
  );
};
