import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { Spinning } from './loading';
import { URL } from '../common/constant';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelection } from './categoryComponent';

// const axios = require('axios').default;
import axios from 'axios';

export const RelocationEnergyMeter = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const history = useHistory();
    const[is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    
    const schema = yup.object().shape({
        division: yup.number().min(1,'This field is required').required('This field is required'),
        contract_account:yup.number().min(10000000, 'Contract account number should be greater than 8 digits').max(999999999, 'Contract account number should not be greater than 9 digits').required('This field is required(Please enter only digits)'),
        reason: yup.string().required('This field is required'),
        location: yup.string().required('This field is required'),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
        category: yup.string().required('Please select one'),
        site_ready_checbox: yup.bool().required().oneOf([true], 'This checkbox must be ticked'),
    });
    
    
    
	const handleFormSubmit =(values) =>{
        
        setLoading(true);
        axios.post(`${URL}/services/energy-meters/`,{...values,request_type:"Relocation"},{
            headers: {
                "Authorization": localStorage.getItem('token'),
            },
        })
        .then(function (response) {
            setLoading(false);
            setSubmitted(true);
            history.push(`/ticket/${response.data.id}?success`);
            
        })
        .catch(function (error) {
            setLoading(false);
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');

        });
        
    }

    return (
        <Container>
            
            <GoBackButton />
            <h3 className="text-center mt-2">
                Relocation/Shifting of energy meter
            </h3>
            <hr></hr>
            
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={{
                    division: 0,
                    contract_account: '',
                    reason: '',
                    location:'',
                    terms:false,
                    site_ready_checbox:false,
                    category:''
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0" >
                            <FloatingLabel label="Division" >
                            <Form.Select 
                                placeholder="Division" 
                                name="division"
                                value={values.division}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.division && !!errors.division}
                                isValid={ touched.division && !errors.division}
                                disabled={is_submitted}
                            >       
                                <option value="0">Choose one / Reset</option>
                                {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location " >
                                <Form.Control type="text" 
                                    placeholder="Name of location " 
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.location && !!errors.location}
                                    isValid={ touched.location && !errors.location}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Consumer No." >
                                <Form.Control type="number" 
                                    placeholder="Consumer No." 
                                    name="contract_account"
                                    value={values.contract_account}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.contract_account && !!errors.contract_account}
                                    isValid={ touched.contract_account && !errors.contract_account}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.contract_account}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2">
                            
                            <FloatingLabel label="Reason" >
                                <Form.Control type="text" 
                                    placeholder="Reason" 
                                    name="reason"
                                    value={values.reason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.reason && !!errors.reason}
                                    isValid={ touched.reason && !errors.reason}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                                
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <CategorySelection 
                            values = {values}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                            label={'Area'}
                        />
                    </Row>
                    
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Check
                                required
                                name="site_ready_checbox"
                                label="Site ready for relocation/shifting of energy meter(s)"
                                onChange={handleChange}
                                isInvalid={!!errors.site_ready_checbox}
                                feedback={errors.site_ready_checbox}
                                feedbackType="invalid"
                                id="site_ready_checbox"
                                disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.site_ready_checbox}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} className="mb-2 ">
                        <Form.Check
                            required
                            name="terms"
                            label="I assure that all the information filled in above are true and that I am liable for any
                            action by the company if any of the information provided are incorrect."
                            onChange={handleChange}
                            isInvalid={!!errors.terms}
                            feedback={errors.terms}
                            feedbackType="invalid"
                            id="terms Feedback"
                            disabled={is_submitted}
                            />
                            <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    

                    <Form.Group as={Col}   className="mt-2">
                        <Button type="submit" disabled={is_loading || is_submitted}>
                            {is_loading ? <Spinning />: null}
                            Submit 
                        </Button>
                    </Form.Group>
                </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    )
}