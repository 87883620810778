import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'

 export  const ErrorModal = ({showModal,setShowModal,modalContent}) => {
    return(
        <Modal show={showModal} onHide={setShowModal} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faTimesCircle} size='md' color="red" className='me-2' />
                    Error
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalContent}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={setShowModal}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
}

export  const SuccessModal = ({showSuccessModal,setShowSuccessModal,successModalContent}) => {
    return(
        <Modal show={showSuccessModal} onHide={setShowSuccessModal} >
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faCheckCircle} size='md' color="green" className='me-2' />
                    Success
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{successModalContent}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={setShowSuccessModal}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
}