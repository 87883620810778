import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory  } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const GoBackButton = () =>{
    const history = useHistory();
    const handleGoBack = () => {
        history.goBack();
    }
    return(
        <Button variant="link" className=' text-secondary text-decoration-none' onClick={handleGoBack}>
            <FontAwesomeIcon icon={faArrowCircleLeft} size='lg' className='me-2' />
            Back
        </Button>
    );
}