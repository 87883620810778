import {Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ServiceMenuComponent = () =>{
    return (
        <>
        <img src="/banner.jpg" className="img-fluid" alt="BPC Banner image"/>
        <Container>
            
            
            <Row xs={1} md={3} lg={4} className="g-4 mt-2">
                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/power-clearance" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                        <Card.Title>Power clearance</Card.Title>
                        
                        </Card.Body>
                        </Link>
                    
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/construction-clearance" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                        <Card.Title>Construction clearance</Card.Title>
                        
                        </Card.Body>
                        </Link>
                    
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/sanction-power" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                        <Card.Title>Sanction of power</Card.Title>
                        
                        </Card.Body>
                        </Link>
                    
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/shifting-electricity-infra" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center text-center">
                            <Card.Title>Shifting of electrical infrastructure</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/release-temporary-power-supply" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title>Temporary power supply</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/release-permanent-power-supply" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title>Permanent power supply</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/energy-meter-upgradation" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title>Upgradation of energy meter</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/energy-meter-relocation" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title>Relocation/Shifting of energy meter </Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/additional-energy-meter-installation" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title>Additional energy meters </Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/testing-replacement-meter" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title> Testing and replacement of energy meter </Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/interim-billing" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title>Interim billing</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/change-customer-information" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title> Change of billing information</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/account-closure-security-refund" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title> Energy meter surrender and closure of account </Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/temporary-disconnection-of-supply" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title> Temporary disconnection of supply </Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>

                <Col>
                    <Card className="shadow bs-card" >
                        <Link to="/reconnection-power-supply" className="text-decoration-none">
                        <Card.Header className="bg-success text-white"></Card.Header>
                        
                        <Card.Body className="bs-card-body text-center">
                            <Card.Title> Reconnection of power supply</Card.Title>
                        </Card.Body>
                        </Link>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    );
}