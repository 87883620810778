import { useHistory } from 'react-router-dom';
import {Row, Col, Form, FloatingLabel, Button, InputGroup, Container, Badge} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { FileUploadField } from './fileUploadComponent';
import { URL } from '../common/constant';
import { Spinning } from './loading';
import { GoBackButton } from '../common/goBackButtonComponent';
import { ErrorModal } from './successModal';
import { CategorySelectionForSanction } from './categoryComponent';
// const axios = require('axios').default;
import axios from 'axios';

export const SanctionPower = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const [formik_values, setFormikValues] = useState({
        location: '',
        division: '',
        reason: '',
        license:'',
        power_clearance:'',
        power_required:'',
        terms:false,
        yes_no:'',
        category:''
    });
    const [hide_show_file, setHideShowFile] = useState('d-none');
    const [hide_show_pc, setHideShowPC] = useState('d-none');

    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const[is_loading, setLoading]= useState(false);
    const[is_submitted, setSubmitted] = useState(false);
    const schema = yup.object().shape({
        location: yup.string().required('This field is required'),
        division: yup.number().min(1, 'This field is required').required('This field is required'),
        reason: yup.string().required('This field is required'),
        license:yup.string().required('This field is required'),
        category:yup.string().required('This field is required'),
        power_clearance:yup.number().required('This field is required'),
        power_required: yup.number().required('This field is required'),
        terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
        yes_no:yup.string().required('Please select one')
    });
    const [old_power_clearance_file, setOldPowerClearanceFile] = useState({file:'',error:null});
    const [license_file, setLicenseFile] = useState({file:'',error:null});
    const [load_details_file, setLoadDetailsFile] = useState({file:'',error:null});
    const [equip_specs_file, setEquipSpecsFile] = useState({file:'',error:null});
    
	const handleFormSubmit =(values) =>{
        if(hide_show_file === 'd-block'){
            if(!old_power_clearance_file.file){
                setOldPowerClearanceFile({...old_power_clearance_file, error:'Old power clearance copy is required'})
                return false;
            }
        }

        if(!license_file.file)
            setLicenseFile({...license_file, error:'License copy is required'})

        else{
            checkPClearanceAndSubmit(values);
        }
    }

    const hideShowROWField = (status, setFieldValue) =>{
        if (status){
            setHideShowFile('d-none');
            setHideShowPC('d-block mb-3');
            setFieldValue('power_clearance', '');
            
        }
        else{
            setHideShowPC('d-none mb-3');
            setHideShowFile('d-block');
            
            setFieldValue('power_clearance', 1);
        }
    }

   
    useEffect(() => {
        
        if(params.get('service_id') !== null){
            setSubmitted(true);
            axios.get(`${URL}/services/sanctions/${params.get('service_id')}/`,
                {headers: {
                    "Authorization": localStorage.getItem('token'),
                }}
            ).then(function (response) {
                setHideShowPC();
                setFormikValues(response.data);
                getProperPCApplicationID(response.data.power_clearance, response.data);
            }).catch(function (error) {
                setShowModal(true);
                if(error.response){
                    setModalContent(JSON.stringify(error.response.data));
                }
                
                else
                    setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        }
    },[]);

    const checkPClearanceAndSubmit = (values) => {
        
        if(values.yes_no === 'no'){
            finalSubmit(values,'no');
        }
        else{
            setLoading(true);
            axios.get(`${URL}/tickets/${values.power_clearance}/`,
                { headers: { "Authorization": localStorage.getItem('token'), }}
            )
            .then(function (response) {
                if(response.data.service === "Power Clearance"){
                    if(response.data.status === "Pending"){
                        setShowModal(true);
                        setModalContent(`Sorry, the Power Clearance application ID ${values.power_clearance} has not been approved yet. Try again next time`);
                        setLoading(false);
                    }
    
                    else if(response.data.status === "Rejected"){
                        setShowModal(true);
                        setModalContent(`Sorry, the Power Clearance application ID ${values.power_clearance} has been Rejected`);
                        setLoading(false);
                    }
    
                    else{
                        
                        finalSubmit(values, response);
                    }
                }
                else{
                    setShowModal(true);
                    setModalContent(`Sorry  power clearance application ID ${values.power_clearance} is not valid`);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setShowModal(true);
                if(error.response){
                    if(error.response.status === 404){
                        setModalContent(`Sorry  power clearance application ID ${values.power_clearance} is not valid`);
                    }
                    else
                        setModalContent(JSON.stringify(error.response));
                }
                else
                    setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        }
    }

    const finalSubmit = (values,response) =>{
        setLoading(true);
        let formData = new FormData();
        formData.append('location', values.location);
        formData.append('reason', values.reason);
        formData.append('division', values.division);
        if(values.yes_no === 'no'){
            formData.append('power_clearance', '');
            formData.append('old_power_clearance', old_power_clearance_file.file);
        }
            
        else
            formData.append('power_clearance', response.data.service_id);

        
        formData.append('license', values.license);
        formData.append('power_required', values.power_required);
        formData.append('license_copy', license_file.file);
        formData.append('load_details_copy', load_details_file.file);
        formData.append('equipment_specs', equip_specs_file.file);
        formData.append('category', values.category);
        
        axios.post(`${URL}/services/sanctions/`,formData,{
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Content-Type": "multipart/form-data",
            },
        }).then(function (response) {
            setLoading(false);
            setSubmitted(true);
            history.push(`/ticket/${response.data.id}?success`);
        }).catch(function (error) {
            setLoading(false);
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
    }

    const getProperPCApplicationID = (power_clearance_pseudo, response_data) =>{
        if(power_clearance_pseudo){
            axios.get(`${URL}/services/power-clearances/${power_clearance_pseudo}/`,
                {headers: {
                    "Authorization": localStorage.getItem('token'),
                }}
            ).then(function (response) {
                setFormikValues({...response_data, power_clearance: response.data.id});
            }).catch(function (error) {
                setShowModal(true)
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        }
    }

    return (
        <Container>
            
            <GoBackButton />
                <h3 className="text-center mt-2">
                {(params.get('ticket'))?<>Application ID <Badge bg="info" className="me-2">{params.get('ticket')}</Badge></>:null}
                Sanction of power

            </h3>
            <hr></hr>
            
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleFormSubmit(values)} 
                initialValues={formik_values}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    setFieldValue,
                    isInvalid,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Name of location where supply is required" >
                                <Form.Control type="text" 
                                    placeholder="Name of location" 
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.location && !!errors.location}
                                    isValid={ touched.location && !errors.location}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Division" >
                            <Form.Select 
                                placeholder="Division" 
                                name="division"
                                value={values.division}
                                onChange ={handleChange}
                                onBlur={handleBlur}
                                isInvalid={ touched.division && !!errors.division}
                                isValid={ touched.division && !errors.division}
                                disabled={is_submitted}
                            >       
                                <option value="0">Choose one / Reset</option>
                                {props.divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option> )}
                            </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.division}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Reason" >
                                <Form.Control type="text" 
                                    placeholder="Reason" 
                                    name="reason"
                                    value={values.reason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.reason && !!errors.reason}
                                    isValid={ touched.reason && !errors.reason}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="License No." >
                                <Form.Control type="text" 
                                    placeholder="License No." 
                                    name="license"
                                    value={values.license}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.license && !!errors.license}
                                    isValid={ touched.license && !errors.license}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.license}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        
                    </Row>

                    <Row className="mb-3" >
                        <Form.Group as={Col} md={6}  className="mb-2">
                            <InputGroup >
                                <FloatingLabel label="Power Required" >
                                    <Form.Control type="number" 
                                        placeholder="Power Required" 
                                        name="power_required"
                                        value={values.power_required}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.power_required && !!errors.power_required}
                                        isValid={ touched.power_required && !errors.power_required}
                                        disabled={is_submitted}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.power_required}</Form.Control.Feedback>
                                </FloatingLabel>
                                    
                                    <InputGroup.Text>kW</InputGroup.Text>
                            </InputGroup>
                            
                        </Form.Group>
                        <CategorySelectionForSanction 
                            values = {values}
                            label={'Category'}
                            handleChange = {handleChange}
                            handleBlur = {handleBlur}
                            touched = {touched}
                            errors ={errors}
                            is_submitted = {is_submitted}
                        />
                        
                    </Row>
                    
                        
                    
                    {
                        (params.get('service_id') !== null)?
                        null:
                        <Row className='mt-4 ms-1 mb-4'>
                                Do you have the previously approved power clearance application ID issued from this system?
                            
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name="yes_no"
                                    type="radio"
                                    id='yes'
                                    onClick={()=>hideShowROWField(true,setFieldValue)}
                                    
                                    value='yes'
                                    onChange={handleChange}
                                    isInvalid={!!errors.yes_no}
                                    feedback={errors.yes_no}
                                    feedbackType="invalid"
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name="yes_no"
                                    type="radio"
                                    id='no'
                                    onClick={()=>hideShowROWField(false,setFieldValue)}
                                    value='no'
                                    onChange={handleChange}
                                    isInvalid={!!errors.yes_no}
                                    feedback={errors.yes_no}
                                    feedbackType="invalid"
                                />
                                
                            </Row>
                    }
                    



                    <Row  className={hide_show_pc}>
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Power Clearance Application ID" >
                                <Form.Control type="number" 
                                    placeholder="Power Clearance Application ID" 
                                    name="power_clearance"
                                    value={values.power_clearance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.power_clearance && !!errors.power_clearance}
                                    isValid={ touched.power_clearance && !errors.power_clearance}
                                    disabled={is_submitted}
                                />
                                <Form.Control.Feedback type="invalid">{errors.power_clearance}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Text className="text-muted">
                            Please use the previously approved power clearance application ID for the service available under view application of this platform. 
                            </Form.Text>
                        </Form.Group>
                    </Row>

                        {
                            (params.get('service_id') !== null)?
                            null:
                            <>
                                <Row className={hide_show_file}> 
                                    <FileUploadField 
                                        name="old_power_clearance" 
                                        label="Old Power Clearance Copy"
                                        file={old_power_clearance_file}
                                        setFile = {setOldPowerClearanceFile}
                                    />
                                </Row>

                                <Row>
                                    <FileUploadField 
                                        name="license_file" 
                                        label="Copy of License"
                                        file={license_file}
                                        setFile = {setLicenseFile}
                                    />

                                    <FileUploadField 
                                        name="load_details_file" 
                                        label="Load Details"
                                        file={load_details_file}
                                        setFile = {setLoadDetailsFile}
                                    />

                                    <FileUploadField 
                                        name="equip_specs_file" 
                                        label="Specifications of electrical equipment to be installed"
                                        file={equip_specs_file}
                                        setFile = {setEquipSpecsFile}
                                    />
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} className="mb-2 ">
                                    <Form.Check
                                        required
                                        name="terms"
                                        label="I assure that all the information filled in above are true and that I am liable for any
                                        action by the company if any of the information provided are incorrect."
                                        onChange={handleChange}
                                        isInvalid={!!errors.terms}
                                        feedback={errors.terms}
                                        feedbackType="invalid"
                                        id="terms Feedback"
                                        disabled={is_submitted}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.terms}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Form.Group as={Col}   className="mt-2">
                                    <Button type="submit" disabled={is_loading || is_submitted}>
                                        {is_loading ? <Spinning />: null}
                                        Submit 
                                    </Button>
                                </Form.Group>
                            </>
                        }

                </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        
        </Container>
    )
}