import {Card, Button, Col, Row, Form, FloatingLabel, Table, Alert, Container} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { URL } from '../common/constant';
import { SERVICES } from '../common/constant';
import { Link } from "react-router-dom";
import { Spinning } from './loading';
import { ErrorModal } from './successModal';
// const axios = require('axios').default;
import axios from 'axios';

export const Ticket = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');
    const[ticket, setTicket] = useState([]);
    const schema = yup.object().shape({
        ticketNo: yup.number('This field is required and it must be a number').required('This field is required and it must be a number type'),
    });
    const search = window.location.search;
    const params = new URLSearchParams(search);
        
    const history = useHistory();
    const { ticketNo } = useParams();
    const [view_all_loading, setViewAllLoading] = useState(false);
    const[ticket_loading, setTicketLoading] = useState(false)
   

    const viewAllTickets = () => {
        setViewAllLoading(true);
        history.push(`/ticket/all`);
        axios.get(`${URL}/tickets/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        )
        .then(function (response) {
            if(response.data){
                setTicket(response.data);
            }
            else{
                
            }
        })
        .catch(function (error) {
            setShowModal(true);
            setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        }).finally(function (){
            setViewAllLoading(false);
        });
    }

    const formatDate = (date1) =>{
        const date = new Date(date1);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();
        return dd + '/' + mm + '/' + yyyy;
    }

    const handleFormSubmit = (values) =>{
        setTicketLoading(true);
        history.push(`/ticket/${values.ticketNo}`);
        axios.get(`${URL}/tickets/${values.ticketNo}/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        )
        .then(function (response) {
            setTicket([response.data]);
        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                if(error.response.status === 404){
                    setModalContent(`Sorry  Application ID ${values.ticketNo} doesn't exists`)
                }
                else
                    setModalContent(JSON.stringify(error.response));

            }
            
            else{
                setShowModal(true);
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            }
                
        }).finally(function (response){
            setTicketLoading(false);
        });
    }

    useEffect(() => {
        
        if(ticketNo){
            if(ticketNo!== 'all'){
                axios.get(`${URL}/tickets/${ticketNo}/`,
                    {headers: {
                        "Authorization": localStorage.getItem('token'),
                    }}
                )
                .then(function (response) {
                    setTicket([response.data]);
                })
                .catch(function (error) {
                    setShowModal(true);
                    if(error.response){
                        if(error.response.status === 404){
                            setModalContent(`Sorry, Application ID ${ticketNo} doesn't exists`);
                        }
                        else{
                            setModalContent(JSON.stringify(error.response));
                        }

                    }
                    
                    else
                        setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
                });
            }
    
            else{
                axios.get(`${URL}/tickets/`,
                    { headers: {
                        "Authorization": localStorage.getItem('token'),
                    }}
                )
                .then(function (response) {
                    setTicket(response.data);
                })
                .catch(function (error) {
                    setShowModal(true);
                    setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');

                });
            }
        }
        else{
            viewAllTickets();
        }
    },[]);

    const handleDownload = (t_no,file_name) =>{
        const b_spinner = document.getElementById(`${t_no}-spinner`);
        const b = document.getElementById(`${t_no}`);
        b.classList.add("disabled");
        b_spinner.classList.remove("d-none");
        b_spinner.classList.add("d-inline-block");
        axios.get(`${URL}/tickets/${t_no}/download/`,
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data],{type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name} Application ID ${t_no}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            b_spinner.classList.remove("d-inline-block");
            b_spinner.classList.add("d-none");
            b.classList.remove("disabled");
        }).catch(function (error) {
            setShowModal(true);
            setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            b_spinner.classList.remove("d-inline-block");
            b_spinner.classList.add("d-block");
            b.classList.remove("disabled");
        });
    }

    

    return(
        <Container>
            <Row>
                <Col>
                    <Card className="mt-2">
                        
                        <Card.Body>
                            <Formik validationSchema={schema} 
                                enableReinitialize={true}
                                onSubmit={(values)=>handleFormSubmit(values)} 
                                initialValues={{
                                    ticketNo:(ticketNo)?ticketNo:null
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isInvalid,
                                    isValid,
                                    errors,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row>
                                    
                                        <Form.Group className="mb-2 mb-md-0" as={Col} sm={7}>
                                            <FloatingLabel label="Application ID" >
                                                <Form.Control type="number" 
                                                    placeholder="Ticket No." 
                                                    name="ticketNo"
                                                    value={values.ticketNo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={ touched.ticketNo && !!errors.ticketNo}
                                                    isValid={ touched.ticketNo && !errors.ticketNo}
                                                />
                                                <Form.Control.Feedback type="invalid">{errors.ticketNo}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                    
                                        <Form.Group  className="mt-2"  as={Col} sm={5}>
                                            <Button type="submit" className="btn btn-success" disabled={ticket_loading}>{(ticket_loading)?<Spinning />:null} Go</Button>
                                            <Button onClick={viewAllTickets} disabled={view_all_loading}  className="btn btn-success ms-3">{(view_all_loading)? <Spinning />:null} View All</Button>
                                        </Form.Group>
                                        </Row>
                                </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Alert   variant='success' className="my-2" show={(params.get('success') !== null)?true: false}>
               Application succesfully submitted. Your Application ID is <b>{ticketNo}</b>. You can  come back to this page and check your Application status anytime. Please contact 1250 for further information
            </Alert>
            
            <Table responsive="sm">
                <thead>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Service</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    {
                        (ticket)?
                            (ticket.length>0)?
                                ticket.map(t=>
                                    <tr>
                                        <td>
                                            <Link className="btn btn-sm btn-success" 
                                                to={`${SERVICES[t.service].component}?service_id=${t.service_id}&ticket=${t.id}`}>
                                                View
                                            </Link>
                                        </td>
                                        <td>{t.id}</td>
                                        <td>{t.name}</td>
                                        <td>{formatDate(t.created)}</td>
                                        <td>
                                            {
                                                (t.status === "Pending")?
                                                    'Under Process'
                                                :(t.status === "Approved")?
                                                    'Delivered'
                                                :t.status
                                            }
                                        </td>
                                        <td>
                                            {
                                                ((t.service === "Power Clearance" || t.service === "Sanction" || t.service === "Site Clearance" || t.service === "Release") && t.status !== "Pending")?
                                                    <Button onClick={()=>{handleDownload(t.id, t.name)}} id={t.id} className=''> <i id={`${t.id}-spinner`} className='d-none'><Spinning /></i>Download</Button>
                                                : null
                                            }
                                        </td>
                                    </tr>
                                )
                            :
                            <tr>
                                <td colSpan="7" className="text-center">No Ticket</td>
                            </tr>
                        :null
                    }
                </tbody>
            </Table>
            <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
        </Container>
    );
}