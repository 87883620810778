import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
  Badge,
  Container,
  Alert,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { Spinning } from "./loading";
import { URL } from "../common/constant";
import { APPLICANT_TYPE } from "../common/constant";
import { FileUploadField } from "./fileUploadComponent";
import { GoBackButton } from "../common/goBackButtonComponent";
import { ErrorModal } from "./successModal";
import { CategorySelection } from "./categoryComponent";
// const axios = require("axios").default;
import axios from 'axios';

export const ChangeCustomerInformation = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const history = useHistory();
  const [formik_values, setFormikValues] = useState({
    division: 0,
    contract_account: "",
    location: "",
    terms: false,
    email: "",
    information: "",
    applicant_type: "0",
    name: "",
    cid: "",
    mobile: "",
    flat: "",
    category: "",
  });

  const [is_loading, setLoading] = useState(false);
  const [is_submitted, setSubmitted] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const schema = yup.object().shape({
    division: yup.number().min(1, "This field is required").required(),
    applicant_type: yup
      .string()
      .test("len", "This field is required", (val) => val.length > 1)
      .required("This field is required"),
    email: yup.string().email("Invalid email format"),
    information: yup.string(),
    name: yup.string(),
    cid: yup.string(),
    mobile: yup.number("This field is required in number"),
    flat: yup.string(),
    category: yup.string().required("Please select one"),
    contract_account: yup
      .number()
      .min(10000000, "Contract account number should be greater than 8 digits")
      .max(
        999999999,
        "Contract account number should not be greater than 9 digits"
      )
      .required("This field is required(Please enter only digits)"),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  const [cidOwnerFile, setCidOwnerFile] = useState({ file: "", error: null });
  const [ownership_change_file, setOwnershipChangeFile] = useState({
    file: "",
    error: null,
  });

  const handleFormSubmit = (values) => {
    let formData = new FormData();
    formData.append("applicant_type", values.applicant_type);
    formData.append("contract_account", values.contract_account);
    formData.append("division", values.division);
    formData.append("name", values.name);
    formData.append("cid", values.cid === undefined ? "" : values.cid);
    formData.append("mobile", values.mobile === undefined ? "" : values.mobile);
    formData.append("flat", values.flat);
    formData.append("email", values.email);
    formData.append("information", values.information);
    formData.append("category", values.category);

    if (values.applicant_type === "Owner") {
      formData.append("reason", values.reason);
      formData.append("cid_copy", cidOwnerFile.file);
      formData.append("ownership_change_copy", ownership_change_file.file);
      formData.append("thram", values.thram);
    } else {
      formData.append("reason", "NA");
    }
    setLoading(true);
    axios
      .post(`${URL}/services/information-changes/`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setLoading(false);
        setSubmitted(true);
        history.push(`/ticket/${response.data.id}?success`);
      })
      .catch(function (error) {
        setLoading(false);
        setShowModal(true);
        if (error.response) {
          setModalContent(JSON.stringify(error.response.data));
        } else setModalContent("Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later");
      });
  };

  useEffect(() => {
    if (params.get("service_id") !== null) {
      setSubmitted(true);
      axios
        .get(
          `${URL}/services/information-changes/${params.get("service_id")}/`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then(function (response) {
          setFormikValues(response.data);
        })
        .catch(function (error) {
          setShowModal(true);
          if (error.response) {
            setModalContent(JSON.stringify(error.response.data));
          } else setModalContent("Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later");
        });
    }
  }, []);

  return (
    <Container>
      <GoBackButton />
      <h3 className="text-center mt-2">
        {params.get("ticket") ? (
          <>
            Application ID{" "}
            <Badge bg="info" className="me-2">
              {params.get("ticket")}
            </Badge>{" "}
          </>
        ) : null}
        Change of billing information
      </h3>
      <hr></hr>

      <Alert variant="warning" className="my-3 text-center" show={true}>
        Kindly fill in only the information needed to be changed in your billing
        information.
      </Alert>

      <Formik
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values) => handleFormSubmit(values)}
        initialValues={formik_values}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isInvalid,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Applicant">
                  <Form.Select
                    placeholder="Applicant"
                    name="applicant_type"
                    value={values.applicant_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.applicant_type && !!errors.applicant_type
                    }
                    isValid={touched.applicant_type && !errors.applicant_type}
                    disabled={is_submitted}
                  >
                    <option value="0">Choose one / Reset</option>
                    {APPLICANT_TYPE.map((applicant_type) => (
                      <option key={applicant_type} value={applicant_type}>
                        {applicant_type}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.applicant_type}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Consumer No.">
                  <Form.Control
                    type="number"
                    placeholder="Consumer No."
                    name="contract_account"
                    value={values.contract_account}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.contract_account && !!errors.contract_account
                    }
                    isValid={
                      touched.contract_account && !errors.contract_account
                    }
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contract_account}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Full Name">
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    isValid={touched.name && !errors.name}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Citizenship ID ">
                  <Form.Control
                    type="number"
                    placeholder="CID"
                    name="cid"
                    value={values.cid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.cid && !!errors.cid}
                    isValid={touched.cid && !errors.cid}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cid}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Mobile No.">
                  <Form.Control
                    type="number"
                    placeholder="Mobile No."
                    name="mobile"
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.mobile && !!errors.mobile}
                    isValid={touched.mobile && !errors.mobile}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Flat No. ">
                  <Form.Control
                    type="text"
                    placeholder="flat"
                    name="flat"
                    value={values.flat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.flat && !!errors.flat}
                    isValid={touched.flat && !errors.flat}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.flat}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>

            {values.applicant_type === "Owner" ? (
              <>
                <Row>
                  <Form.Group as={Col} md={6} className="mb-2">
                    <FloatingLabel label="Thram No.">
                      <Form.Control
                        type="text"
                        placeholder="Thram No."
                        name="thram"
                        value={values.thram}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.thram && !!errors.thram}
                        isValid={touched.thram && !errors.thram}
                        disabled={is_submitted}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.thram}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} md={6} className="mb-2">
                    <FloatingLabel label="Reason for change of ownership">
                      <Form.Control
                        type="text"
                        placeholder="Reason for change of ownership"
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reasons && !!errors.reasons}
                        isValid={touched.reasons && !errors.reasons}
                        disabled={is_submitted}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.reasons}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Row>
                {params.get("service_id") !== null ? null : (
                  <>
                    <Row>
                      <FileUploadField
                        name="cidOwner"
                        label="Copy of CID of new proprietor "
                        file={cidOwnerFile}
                        setFile={setCidOwnerFile}
                      />
                      <FileUploadField
                        name="ownership_change_file"
                        label="Copy of proof of change of ownership of the property"
                        file={ownership_change_file}
                        setFile={setOwnershipChangeFile}
                      />
                    </Row>
                  </>
                )}
              </>
            ) : null}

            <Row className="mb-3">
              <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                <FloatingLabel label="Division ">
                  <Form.Select
                    placeholder="Division"
                    name="division"
                    value={values.division}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.division && !!errors.division}
                    isValid={touched.division && !errors.division}
                    disabled={is_submitted}
                  >
                    <option value="0">Choose one / Reset</option>
                    {props.divisions.map((division) => (
                      <option key={division.id} value={parseInt(division.id)}>
                        {division.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.division}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md={6} className="mb-2">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={6} className="mb-2">
                <FloatingLabel label="Any other information">
                  <Form.Control
                    type="text"
                    placeholder="Any other information"
                    name="information"
                    value={values.information}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.information && !!errors.information}
                    isValid={touched.information && !errors.information}
                    disabled={is_submitted}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.information}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <CategorySelection
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                is_submitted={is_submitted}
                label={"Area"}
              />
            </Row>
            {params.get("service_id") !== null ? null : (
              <>
                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-2 ">
                    <Form.Check
                      required
                      name="terms"
                      label="I assure that all the information filled in above are true and that I am liable for any
                                    action by the company if any of the information provided are incorrect."
                      onChange={handleChange}
                      isInvalid={!!errors.terms}
                      feedback={errors.terms}
                      feedbackType="invalid"
                      id="terms Feedback"
                      disabled={is_submitted}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.terms}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </>
            )}
            <Form.Group as={Col} className="mt-2">
              <Button
                type="submit"
                disabled={is_loading || is_submitted}
                hidden={params.get("service_id") !== null ? true : false}
              >
                {is_loading ? <Spinning /> : null}
                Submit
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <ErrorModal
        showModal={showModal}
        setShowModal={() => setShowModal(!showModal)}
        modalContent={modalContent}
      />
    </Container>
  );
};
