import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

export const NavBar = (props) =>{
    const history=useHistory();
    const handleLogout =() =>{
        localStorage.removeItem('token');
        props.setCustomer({
            checkingIfCustomerIsRegistered: false,
            disabled: false,
            loggedIn:false,
            registeredCustomer:false,
            identification:'',
            mobile:'',
            name:'',
            email:'',
            fixed_line:'',
            village:'',
            gewog:'',
            dungkhag:'',
            dzongkhag:'',
            present_address:'',
            present_dzongkhag:''
        });
        history.push(`/`);
    }

    return(
        <Navbar  className="text-warning bg-white bs-navbar shadow-sm mb-3" expand="lg">
            <Container className="justify-content-center">
            <Nav className="me-auto d-none d-lg-block">
                
                <Button as={Link} to="/ticket" variant="link" className="text-decoration-none text-secondary">
                    <FontAwesomeIcon icon={faFileAlt} size='lg' className='me-2' />
                    View Application
                </Button>
            </Nav>
            

            <Navbar.Brand className="bs-navbar-brand"  as={Link} to="/">
                <img
                   src="/logo.png"
                    className="d-inline-block align-top ps-5 ms-5 ms-md-0"
                    alt="Online Service Request"
                    id="logo"
                />
            </Navbar.Brand>

            
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end" />
                <Navbar.Collapse id="basic-navbar-nav bg-white " >
                <Nav className="me-auto d-block d-lg-none ">
                    <NavDropdown.Item as={Link} className="px-0 my-2 py-2 border-top border-bottom text-secondary" to="/ticket" >
                        <FontAwesomeIcon icon={faFileAlt} size='lg' className='me-2' />
                        View Application
                    </NavDropdown.Item>
                </Nav>
                </Navbar.Collapse>
                
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {
                        (props.customer.loggedIn)?
                        <>
                        <FontAwesomeIcon icon={faUser} size='lg'  />
                        <NavDropdown title={props.customer.name} className="d-inline-block pl-0" variant="dark">
                            <NavDropdown.Item as={Link} to="/customer-register" >Profile</NavDropdown.Item>
                            <hr className="my-1"></hr>
                            <NavDropdown.Item onClick={handleLogout} >logout</NavDropdown.Item>
                        </NavDropdown>
                        </>:
                            <Link to="/customer-login" className="btn bs-btn-signin text-white">Sign In</Link>
                        }
                    </Navbar.Text>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}