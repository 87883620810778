import { Col, Form, FloatingLabel } from "react-bootstrap";

export const CategorySelection = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  is_submitted,
  label,
}) => {
  return (
    <Form.Group as={Col} md={6} className="mb-2">
      <FloatingLabel label={label}>
        <Form.Select
          placeholder={label}
          name="category"
          value={values.category}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.category && !!errors.category}
          isValid={touched.category && !errors.category}
          disabled={is_submitted}
        >
          <option value="">Choose one </option>
          <option value="Rural">Rural </option>
          <option value="Urban">Urban </option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.category}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
};

export const CategorySelectionForSanction = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  is_submitted,
  label,
}) => {
  return (
    <Form.Group as={Col} md={6} className="mb-2">
      <FloatingLabel label={label}>
        <Form.Select
          placeholder={label}
          name="category"
          value={values.category}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.category && !!errors.category}
          isValid={touched.category && !errors.category}
          disabled={is_submitted}
        >
          <option value="">Choose one </option>
          <option value="MV">MV </option>
          <option value="HV">HV </option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.category}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
};

export const CategorySelectionForPowerClearance = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  is_submitted,
  label,
}) => {
  return (
    <Form.Group as={Col} md={6} className="mb-2">
      <FloatingLabel label={label}>
        <Form.Select
          placeholder={label}
          name="category"
          value={values.category}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.category && !!errors.category}
          isValid={touched.category && !errors.category}
          disabled={is_submitted}
        >
          <option value="">Choose one </option>
          <option value="LV Rural">LV Rural</option>
          <option value="LV Urban">LV Urban</option>
          <option value="LV Other">LV Other</option>
          <option value="MV">MV</option>
          <option value="HV">HV</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.category}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
};

export const CategorySelectionForShifting = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  is_submitted,
  label,
}) => {
  return (
    <Form.Group as={Col} md={6} className="mb-2">
      <FloatingLabel label={label}>
        <Form.Select
          placeholder={label}
          name="category"
          value={values.category}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.category && !!errors.category}
          isValid={touched.category && !errors.category}
          disabled={is_submitted}
        >
          <option value="">Choose one </option>
          <option value="LV within 1km">LV within 1km</option>
          <option value="MV within 3km">MV within 3km</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.category}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
};
