import {Row, Col, Form, FloatingLabel, Button, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinning } from './loading';
import { useParams } from 'react-router';
import { Redirect } from 'react-router';
import { URL } from '../common/constant';
import { ErrorModal, SuccessModal } from './successModal';
// const axios = require('axios').default;
import axios from 'axios';

export const CustomerRegisterComponent = (props) =>{
    const[showModal,setShowModal] = useState(false);
    const[modalContent,setModalContent]= useState('');

    const[showSuccessModal,setShowSuccessModal] = useState(false);
    const[successModalContent,setSuccessModalContent]= useState('');

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [gewogs_main, setGewogsMain] = useState([]);
    const [gewogs, setGewogs] = useState([]);
    const [edit_mode, setEditMode] =useState(false);
    useEffect(() => {
        axios.get(`${URL}/addresses/gewogs/`)
        .then(function (response) {
            setGewogsMain(response.data);
            setGewogs(response.data);
        })
        .catch(function (error) {
            setShowModal(true);
            if(error.response){
                setModalContent(JSON.stringify(error.response.data));
            }
            
            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');

        });
    },[]);
    

    const schema = yup.object().shape({
        identification: yup.number().min(10000000000,'CID number should be equal to 11 digits').max(99999999999,'CID number should be equal to 11 digits only').required('Citizenship Identification Number is a required field'),
        mobile: yup.number().min(10000000,'Minimum number is 10000000').max(79999999,'Maximum number 7999999').required(),
        name: yup.string().required(),
        email:yup.string().email('Invalid email format'),
        village: yup.string().min(2).required(),
        gewog: yup.number().min(1, 'Please select Gewog').required(),
        dzongkhag: yup.number().min(1, 'Please select Dzongkhag').required(),
        present_address: yup.string().required('Present Address is a required field'),
        present_dzongkhag: yup.number().min(1, 'Please select Dzongkhag').required(),
    });
    const identificationRef = useRef();
    const mobileRef = useRef();
    const customerNameRef = useRef();
    let { url } = useParams();
    let { ticketNo } = useParams();

    const handleCustomerReset =() =>{
        localStorage.removeItem('token');
        props.setCustomer({
            checkingIfCustomerIsRegistered: false,
            disabled: false,
            loggedIn:false,
            registeredCustomer:false,
            identification:'',
            mobile:'',
            name:'',
            email:'',
            fixed_line:'',
            village:'',
            gewog:'',
            dungkhag:'',
            dzongkhag:'',
            present_address:'',
            present_dzongkhag:''
        });
        history.push(`/`);
    }

    if(props.customer.loggedIn){
        if(url && ticketNo){
            return( <Redirect to={`/${url}/${ticketNo}`} />  );
        }

        else if(url){
            return( <Redirect to={`/${url}`} />  );
        }
    }

    const handleRegisterCustomer = (values) => {
        setLoading(true);
        const new_gewog = gewogs_main.find(gm => gm.id === parseInt(values.gewog));
        const new_permanent_dzongkhag = props.dzongkhags.find(d => d.id === parseInt(values.dzongkhag));
        const new_present_dzongkhag = props.dzongkhags.find(d => d.id === parseInt(values.present_dzongkhag));
        axios.post(`${URL}/customers/`,{
            cid: identificationRef.current.value,
            mobile: mobileRef.current.value,
            name: values.name,
            fixed_line: parseInt(values.fixed_line),
            email: values.email,
            current_address: {
                address: values.present_address,
                dzongkhag: new_present_dzongkhag.name
            },
            permanent_address: {
                village: values.village,
                gewog: new_gewog.name ,
                dzongkhag: new_permanent_dzongkhag.name
            }
        })
        .then(function (response) {
            setShowSuccessModal(true);
            setSuccessModalContent('Succesfully Registered');
            props.setCustomer({
                ...props.customer,
                identification: identificationRef.current.value,
                mobile: mobileRef.current.value
            });
            setLoading(false);
            setTimeout(() => {
                history.push(`/customer-login`)
              }, 3000);
            
        })
        .catch(function (error) {
            setLoading(false);
            setShowModal(true)
            if(error.response)  
                setModalContent(JSON.stringify(error.response.data));

            else
                setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
        });
        
    }

    const enableAllFields = () =>{
        setEditMode(true);
        
        props.setCustomer({
            ...props.customer,
            disabled:false
        });

    }

    const handleCancelEdit =()=>{
        setEditMode(false);
        
        props.setCustomer({
            ...props.customer,
            disabled:true
        });
    }
    const handleUpdateProfile =(values) =>{
        
        schema.validate(values)
        .then(function (valid) {
            setLoading(true);
            
            const new_gewog = gewogs_main.find(gm => gm.id === parseInt(values.gewog));
            const new_permanent_dzongkhag = props.dzongkhags.find(d => d.id === parseInt(values.dzongkhag));
            const new_present_dzongkhag = props.dzongkhags.find(d => d.id === parseInt(values.present_dzongkhag));
            
            axios.patch(`${URL}/customers/${values.identification}/`,{
                cid: values.identification,
                mobile: values.mobile,
                name: values.name,
                email: values.email,
                current_address: {
                    address: values.present_address,
                    dzongkhag: new_present_dzongkhag.name
                },
                permanent_address: {
                    village: values.village,
                    gewog: new_gewog.name ,
                    dzongkhag: new_permanent_dzongkhag.name
                }
            },
            {headers: {
                "Authorization": localStorage.getItem('token'),
            }}).then(function (response) {
                setLoading(false);
                setEditMode(false);
                props.setCustomer({
                    ...props.customer,
                    disabled:true,
                    mobile:values.mobile,
                    name:values.name,
                    email:values.email,
                    village:values.village,
                    gewog:values.gewog,
                    dzongkhag:values.dzongkhag,
                    present_address:values.present_address,
                    present_dzongkhag:values.present_dzongkhag
                });
                setShowSuccessModal(true);
                setSuccessModalContent('Profile succesfully updated.');
            })
            .catch(function (error) {
                setShowModal(true);
                setLoading(false);
                if(error.response){
                    setModalContent(JSON.stringify(error.response.data));
                }
                else
                    setModalContent('Oops something went wrong. Either you are disconnected from internet or the service is down. Please try again later');
            });
        });
    }

    const handleChangeGewog = (selected_gewog_id,setFieldValue) =>{
        if(selected_gewog_id !== "0"){
            const selected_dzongkhag_id = gewogs.find(gewog => parseInt(selected_gewog_id) === parseInt(gewog.id));
            setFieldValue('dzongkhag', selected_dzongkhag_id.dzongkhag);
        }

        else{
            setGewogs(gewogs_main);
            setFieldValue('dzongkhag', 0);
        }
    }

    const handleChangeDzongkhag = (selected_dzongkhag_id) =>{
        
        if(selected_dzongkhag_id !== "0"){
            const filtered_gewogs = gewogs_main.filter(gewog => parseInt(gewog.dzongkhag) === parseInt(selected_dzongkhag_id));
            setGewogs(filtered_gewogs);
        }
            
        else{
            setGewogs(gewogs_main);
        }
    }

    return (
        <Container>
            <h6 className="mt-3">Customer Information </h6>
            
            <Formik validationSchema={schema} 
                enableReinitialize={true}
                onSubmit={(values)=>handleRegisterCustomer(values)} 
                initialValues={props.customer} 
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    setFieldValue,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        
                        <Form.Group as={Col} md={6} className="mb-2 mb-md-0">
                            <FloatingLabel label="Citizenship ID/ Work Permit Number" >
                                <Form.Control type="number" 
                                    size="sm"
                                    placeholder="Citizenship ID" 
                                    name="identification"
                                    value={values.identification}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.identification && !!errors.identification}
                                    isValid={ touched.identification && !errors.identification}
                                    ref={identificationRef}
                                    disabled={props.customer.loggedIn}
                                />
                                <Form.Control.Feedback type="invalid">{errors.identification}</Form.Control.Feedback>
                            </FloatingLabel>
                            
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Mobile" >
                                <Form.Control type="number" 
                                    placeholder="phone number" 
                                    name="mobile"
                                    size="sm"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.mobile && !!errors.mobile}
                                    isValid={ touched.mobile && !errors.mobile}
                                    // disabled={props.customer.disabled}
                                    disabled={props.customer.loggedIn}
                                    ref = { mobileRef }
                                />
                                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Full Name" >
                                <Form.Control type="text" 
                                    placeholder="Full Name" 
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.name && !!errors.name}
                                    isValid={ touched.name && !errors.name}
                                    // disabled={props.customer.disabled}
                                    disabled={props.customer.loggedIn}
                                    ref = { customerNameRef }
                                />
                                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} controlId="" className="mb-2 ">
                            <FloatingLabel label="Email" >
                                    <Form.Control type="email" 
                                        placeholder="Email" 
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.email && !!errors.email}
                                        isValid={ touched.email && !errors.email}
                                        // disabled={ props.customer.disabled }
                                        disabled={props.customer.loggedIn}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} controlId="" className="mb-2 d-none">
                            <FloatingLabel label="Fixed Phone Number" >
                                    <Form.Control type="number" 
                                        placeholder="Fixed Phone Number" 
                                        name="fixed_line"
                                        value={values.fixed_line}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={ touched.fixed_line && !!errors.fixed_line}
                                        isValid={ touched.fixed_line && !errors.fixed_line}
                                        disabled={props.customer.loggedIn}
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.fixed_line}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>

                    <h6 className="mt-3">Permanent Address </h6>

                    <Row className="mb-3">
                    <Form.Group as={Col} md={6} className="mb-2 mb-md-0" >
                            <FloatingLabel label="Dzongkhag" >
                                
                                <Form.Select 
                                    placeholder="Dzongkhag" 
                                    name="dzongkhag"
                                    disabled = { props.customer.loggedIn }
                                    value={values.dzongkhag}
                                    //onChange={handleChange}
                                    onChange ={e => {handleChange(e); handleChangeDzongkhag(e.target.value)}}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.dzongkhag && !!errors.dzongkhag}
                                    isValid={ touched.dzongkhag && !errors.dzongkhag}
                                >       <option value="0">Choose one / Reset</option>
                                        {props.dzongkhags.map(dzongkhag => <option key={dzongkhag.id} value={dzongkhag.id}>{dzongkhag.name}</option> )}
                                    </Form.Select>

                                <Form.Control.Feedback type="invalid">{errors.dzongkhag}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} controlId="" className="mb-2 ">
                            <FloatingLabel label="Gewog" >
                                <Form.Select 
                                    placeholder="Gewog" 
                                    name="gewog"
                                    disabled={props.customer.loggedIn}
                                    value={values.gewog}
                                    onChange={e => {handleChange(e); handleChangeGewog(e.target.value, setFieldValue)}}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.gewog && !!errors.gewog}
                                    isValid={ touched.gewog && !errors.gewog}
                                >
                                        <option value="0">Choose one / Reset</option>
                                        {   
                                            gewogs.map(gewog => <option key={gewog.id} value={gewog.id}>{gewog.name}</option> )
                                            
                                        }
                                    </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.gewog}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6}  className="mb-2 mb-md-0">
                            <FloatingLabel label="Village" >
                                <Form.Control type="text" 
                                    placeholder="Village" 
                                    disabled={props.customer.loggedIn}
                                    name="village"
                                    value={values.village}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.village && !!errors.village}
                                    isValid={ touched.village && !errors.village}
                                />
                                <Form.Control.Feedback type="invalid">{errors.village}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group> 
                    </Row>
                    <h6 className="mt-3">Present Address </h6>

                    <Row className="mb-3">
                        <Form.Group  className="mb-2 mb-md-0" as={Col} md={6}>
                            <FloatingLabel label="Present Address" >
                                <Form.Control type="text" 
                                    placeholder="Present Address" 
                                    disabled={props.customer.disabled}
                                    name="present_address"
                                    value={values.present_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.present_address && !!errors.present_address}
                                    isValid={ touched.present_address && !errors.present_address}
                                />
                                <Form.Control.Feedback type="invalid">{errors.present_address}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} md={6} >
                            <FloatingLabel label="Present Dzongkhag" >
                                
                                <Form.Select 
                                    placeholder="Present Dzongkhag" 
                                    name="present_dzongkhag"
                                    disabled={props.customer.disabled}
                                    value={values.present_dzongkhag}
                                    onChange ={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={ touched.present_dzongkhag && !!errors.present_dzongkhag}
                                    isValid={ touched.present_dzongkhag && !errors.present_dzongkhag}
                                >       <option value="0">Choose one / Reset</option>
                                        {props.dzongkhags.map(dzongkhag => <option key={dzongkhag.id} value={dzongkhag.id}>{dzongkhag.name}</option> )}
                                    </Form.Select>

                                <Form.Control.Feedback type="invalid">{errors.present_dzongkhag}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    {
                        (!props.customer.loggedIn)?
                        <Form.Group as={Col}   className="mt-2">
                            <Button type="submit" disabled={loading}>
                                {loading ? <Spinning />: null}
                                Register Now 
                            </Button>
                        </Form.Group>
                        : 
                        <Form.Group as={Col} className="mt-2">
                            {
                                (!edit_mode)?
                                <>
                                    <Button type="button" size="lg" variant='secondary' onClick={enableAllFields} >
                                        Edit
                                    </Button>

                                    <Button type="button" size="lg" className='float-end' onClick={handleCustomerReset} >
                                        logout
                                    </Button>
                                </>:
                                <>
                                    <Button type="button" size="lg" variant='danger' onClick={handleCancelEdit} >
                                        Cancel
                                    </Button>

                                    <Button type="button" size="lg"  className='ms-4' onClick={()=> handleUpdateProfile(values)}>
                                       {loading? <Spinning />:null} Update
                                    </Button>
                                </>
                            }
                        </Form.Group>
                    }

                </Form>
                )}
           </Formik>
           <ErrorModal showModal={showModal} setShowModal={()=>setShowModal(!showModal)} modalContent={modalContent} />
           <SuccessModal showSuccessModal={showSuccessModal} setShowSuccessModal={()=>setShowSuccessModal(!showSuccessModal)} successModalContent={successModalContent} />
      
        </Container>
    );
}